export const environment = {
  production: false,
  baseURL:
    'https://test-stopover.adminmodules.etihad.ae/ada-services/stopoveradmin',
  OKTAClientID: '0oa21igu7tkvgGkyx0h8',
  OKTAIssuer: 'https://etihad.oktapreview.com',
  OKTArediRectUri:
    'https://test-stopover.adminmodules.etihad.ae/login/callback',
  coreDataURL: 'https://www.etihad.com',
  env: 'TEST',
};